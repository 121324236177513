import React, { useState, useEffect, useRef } from "react";
import { Box, Button, Grid, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import AlaiLogo from "../images/alailogo.png";
import CavinKareLogo from "../images/cavinkarelogo.png";

const MainScreen = () => {
  let navigate = useNavigate();

  const windowWidth = useRef(window.innerWidth);

  function getCurrentDimension() {
    return {
      width: window.innerWidth,
      height: window.innerHeight,
    };
  }

  const [screenSize, setScreenSize] = useState(getCurrentDimension());

  useEffect(() => {
    const updateDimension = () => {
      setScreenSize(getCurrentDimension());
    };
    window.addEventListener("resize", updateDimension);

    return () => {
      window.removeEventListener("resize", updateDimension);
    };
  }, [screenSize]);

  return (
    <React.Fragment>
      <Box
        sx={{
          width: "100wh",
          maxHeight: "100vh",
          height: "100vh",
        }}
      >
        <Grid container>
          
          <Grid
            item
            sm={12}
            xs={12}
            md={12}
            gap={1}
            sx={{
              height: "88vh",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Grid
              item
              sm={12}
              xs={12}
              md={12}
              sx={{
                background: "#FFFFFF",
                display: "flex",
                alignItems: "center",
                height: "100%",
                borderRadius: "10px",
                marginLeft: "10px",
                width: "100%",
                flexDirection: "column",
              }}
            >
              <div
                style={{
                  flex: 0.2,
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <img
                  src={CavinKareLogo}
                  width={windowWidth.current / 1.5}
                  height={windowWidth.current / 1.8}
                  alt="ALAI Technology"
                />
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection:
                    screenSize.width < screenSize.height ? "column" : "row",
                  width: "100%",
                  justifyContent: "space-evenly",
                  flex: 1,
                  alignItems: "center",
                  marginBottom: "15%"
                }}
              >
                <Button
                  variant="contained"
                  sx={{
                    height: screenSize.height / 15,
                    width: screenSize.width < screenSize.height ? "50%" : "30%",
                    fontSize: windowWidth.current / 25,
                  }}
                  onClick={() => navigate("/scan", { state: { machineID: 1 } })}
                >
                  Scan
                </Button>
                {/* <Button
                  variant="contained"
                  sx={{
                    height: screenSize.height / 8,
                    width: screenSize.width < screenSize.height ? "50%" : "30%",
                    fontSize: windowWidth.current / 25,
                  }}
                  onClick={() => navigate("/ocr", { state: { machineID: 2 } })}
                >
                  OCR
                </Button> */}
              </div>
              <Typography
                sx={{
                  color: "#000000",
                  flex: 0.05,
                  fontSize: windowWidth.current / 35,
                }}
              >
                {`Copyright @ ${new Date().getFullYear()} Alai Technology Labs. All rights reserved`}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </React.Fragment>
  );
};

export default MainScreen;
